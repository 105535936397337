._16lirji0 {
  overflow-y: clip;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
._16lirji1 {
  box-sizing: content-box;
  height: 100vh;
  position: relative;
  width: 100%;
}
._16lirji2 {
  align-items: center;
  display: flex;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 100%;
}
._16lirji3 {
  min-width: 100%;
  position: relative;
  width: 100%;
}
._16lirji4 {
  height: auto;
  left: 50%;
  min-width: 100%;
  position: relative;
  transform: translateX(-50%);
  width: 100vw;
}
._16lirji5 {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
._16lirji6 {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100%;
  width: 100%;
}
._16lirji6 .v6jvm52 {
  width: var(--_1ivttc5fq);
}
@media screen and (min-width:90em) {
  ._16lirji1 {
    max-width: var(--_1ivttc5dg);
  }
  ._16lirji6 > .v6jvm57 > .v6jvm54 {
    max-width: var(--_1ivttc5fq);
  }
}