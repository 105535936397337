._1f0prva0 {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  padding-top: 32px;
}
@media screen and (min-width:48em) {
  ._1f0prva0 {
    width: var(--_1ivttc5d4);
    padding: var(--_1ivttc5cq);
  }
}
@media screen and (min-width:64em) {
  ._1f0prva0 {
    flex-direction: row;
    padding-top: 80px;
    width: var(--_1ivttc5de);
    padding: 56px;
  }
}